import { useCallback, useContext } from "react";
import Particles from "react-particles";
import type { Container, Engine } from "tsparticles-engine";
import { loadSlim } from "tsparticles-slim";
import { InitializeContext } from "../App";

export default function TsParticles() {
          const { theme } = useContext(InitializeContext);
          const particlesInit = useCallback(async (engine: Engine) => {
                    await loadSlim(engine);
          }, []);

          const particlesLoaded = useCallback(async (container: Container | undefined) => {
          }, []);
          return (
                    <Particles
                              id="tsparticles"
                              init={particlesInit}
                              loaded={particlesLoaded}
                              options={{
                                        background: {
                                                  color: {
                                                            value: "",
                                                  },
                                        },
                                        fpsLimit: 120,
                                        interactivity: {
                                                  events: {
                                                            onClick: {
                                                                      enable: true,
                                                                      mode: "push",
                                                            },
                                                            onHover: {
                                                                      enable: true,
                                                                      mode: "repulse",
                                                            },
                                                            resize: true,
                                                  },
                                                  modes: {
                                                            push: {
                                                                      quantity: 4,
                                                            },
                                                            repulse: {
                                                                      distance: 200,
                                                                      duration: 0.4,
                                                            },
                                                  },
                                        },
                                        particles: {
                                                  color: {
                                                            value: theme ? "#fff" : "#000",
                                                  },
                                                  links: {
                                                            color: theme ? "#fff" : "#000",
                                                            distance: 150,
                                                            enable: true,
                                                            opacity: 0.5,
                                                            width: 1,
                                                  },
                                                  move: {
                                                            direction: "none",
                                                            enable: true,
                                                            outModes: {
                                                                      default: "bounce",
                                                            },
                                                            random: false,
                                                            speed: 6,
                                                            straight: false,
                                                  },
                                                  number: {
                                                            density: {
                                                                      enable: true,
                                                                      area: 800,
                                                            },
                                                            value: 80,
                                                  },
                                                  opacity: {
                                                            value: 0.5,
                                                  },
                                                  shape: {
                                                            type: "circle",
                                                  },
                                                  size: {
                                                            value: { min: 1, max: 5 },
                                                  },
                                        },
                                        detectRetina: true,
                              }}
                    />
          )
}
