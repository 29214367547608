import React, { useContext } from 'react'
import { InitializeContext } from '../App';

export default function Preloader() {
          const { theme } = useContext(InitializeContext);
          const text = "</KiroN>";

          return (
                    <section className={`w-full h-screen m-0 p-0 flex justify-center items-center flex-row flex-nowrap ${theme ? 'bg-[#212428]' : 'bg-[#f7f7f7]'}`}>
                              <div className={`w-[120px] h-[120px] flex flex-row flex-nowrap justify-center items-center relative ${theme ? 'bg-[#212428] animate-dark-shadows' : 'bg-white animate-shadows'} rounded-[20px] origin-center rotate-45`}>
                                        <h1 className={`text-xl font-medium origin-center -rotate-45 ${theme ? 'text-white' : 'text-black'} font-Poppins`}>{text}</h1>
                                        <div className={`w-[120px] h-[120px] rounded-[20px] absolute ${theme ? 'bg-[#212428]' : 'bg-[#f7f7f7]'} opacity-[0.45] bg-no-repeat`}></div>
                              </div>
                    </section>
          )
}
