import React, { useContext } from 'react';
import Avatar from '../assets/avatar.jpg';
import IMDb from '../assets/imdb.png';
import { BsGlobe, BsFacebook, BsMessenger, BsGithub, BsInstagram, BsLinkedin, BsYoutube, BsTiktok, BsReddit, BsWhatsapp, BsTelegram, BsSpotify, BsStackOverflow, BsPinterest } from 'react-icons/bs'
import { FaXTwitter, FaDeezer } from 'react-icons/fa6'
import { FcGoogle } from 'react-icons/fc'
import { HiOutlineLocationMarker } from 'react-icons/hi'
import { BiSun, BiMoon } from 'react-icons/bi'
import { SiApplemusic } from 'react-icons/si'
import { InitializeContext } from '../App';
import styles from './Profile.module.css'

const Fade = require("react-reveal/Fade");

export default function Profile() {
          const { theme, handleThemeChange } = useContext(InitializeContext);

          const handleClick = (url: string) => {
                    window.open(url, "_blank", "noopener,noreferrer");
          };

          return (
                    <Fade top duration={1000} distance="40px">
                              <div className="hero z-50">
                                        <div className="hero-content flex-col justify-between lg:flex-row w-full p-0">
                                                  <div className='flex flex-col justify-center items-center w-full h-screen'>
                                                            <div className="bg-transparent backdrop-blur-lg w-full sm:w-4/5 md:w-4/5 xl:w-1/2 sm:rounded-[3rem] sm:shadow-md md:border">
                                                                      <div className="md:card-body text-center">
                                                                                <Fade bottom duration={3000} distance="40px">
                                                                                          <div className={`bg-transparent hidden md:flex z-50 absolute top-2 md:top-4 right-2 md:right-4 rounded-xl px-3 py-1`}>
                                                                                                    <button
                                                                                                              onClick={handleThemeChange}>
                                                                                                              {
                                                                                                                        theme ? (
                                                                                                                                  <BiSun size={20} className={`${theme ? 'text-white' : 'text-black'}`} />
                                                                                                                        ) : (
                                                                                                                                  <BiMoon size={20} className={`${theme ? 'text-white' : 'text-black'}`} />
                                                                                                                        )
                                                                                                              }
                                                                                                    </button>
                                                                                          </div>
                                                                                </Fade>
                                                                                <Fade top duration={2000} distance="40px">
                                                                                          <div className='flex justify-center items-center'>
                                                                                                    <div className="avatar">
                                                                                                              <div className={`w-48 md:w-60 rounded-full ring ${theme ? "ring-[#4bb3f0]" : "ring-[#8b5cf6]"} ring-offset-base-100 ring-offset-2 hover:cursor-not-allowed`}>
                                                                                                                        <img src={Avatar} alt='Toufiq Hasan Kiron' />
                                                                                                              </div>
                                                                                                    </div>
                                                                                          </div>
                                                                                </Fade>
                                                                                <Fade top duration={3000} distance="40px">
                                                                                          <div className={`flex flex-col justify-center items-center pt-7 select-none ${theme && 'text-white'}`}>
                                                                                                    <Fade top duration={3000} distance="40px">
                                                                                                              <h2 className="text-2xl md:text-3xl font-bold" title='Toufiq Hasan Kiron'>Toufiq Hasan Kiron</h2>
                                                                                                    </Fade>
                                                                                                    <Fade top duration={3000} distance="40px">
                                                                                                              <p className='text-sm md:text-xl font-semibold pb-3'>MERN Stack Developer</p>
                                                                                                    </Fade>
                                                                                                    <Fade top duration={3000} distance="40px">
                                                                                                              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '10px' }}>
                                                                                                                        <HiOutlineLocationMarker size={20} className={`${theme ? 'text-white' : 'text-black'}`} />
                                                                                                                        <span className='font-semibold'>Bogura, Bangladesh</span>
                                                                                                              </div>
                                                                                                    </Fade>
                                                                                          </div>
                                                                                </Fade>


                                                                                <Fade top duration={4000} distance="40px">
                                                                                          <div className="flex flex-col items-center justify-center mb-[1rem] pt-8">
                                                                                                    <h2 className={`flex items-center gap-2 uppercase text-lg md:text-[1.5rem] font-bold text-center select-none ${theme && 'text-white'}`}>
                                                                                                              All Social Links
                                                                                                    </h2>
                                                                                                    <Fade bottom duration={3000} distance="40px">
                                                                                                              <div className="flex items-center justify-center gap-1 my-2">
                                                                                                                        <p className={`w-24 h-1 bg-gradient-to-r ${theme ? "from-[#4bb3f0] to-[#ee82ee]" : "from-[#8b5cf6] to-[#ee82ee]"}`}></p>
                                                                                                                        <p className={`w-8 h-1 bg-gradient-to-r ${theme ? "from-[#ee82ee] to-[#4bb3f0]" : "from-[#ee82ee] to-[#8b5cf6]"}`}></p>
                                                                                                                        <p className={`w-4 h-1 bg-gradient-to-r ${theme ? "from-[#4bb3f0] to-[#ee82ee]" : "from-[#8b5cf6] to-[#ee82ee]"}`}></p>
                                                                                                              </div>
                                                                                                    </Fade>
                                                                                          </div>
                                                                                </Fade>

                                                                                <Fade top duration={5000} distance="40px">
                                                                                          <div className={`flex justify-center items-center flex-wrap ${styles.socialLinks}`}>
                                                                                                    <span onClick={() => handleClick('https://toufiqhasankiron.com')} className={`${styles.website} cursor-pointer tooltip inline-flex justify-center items-center text-white relative flex-shrink-0 w-12 h-12 m-3 md:m-4 rounded-xl md:rounded-2xl transition-all duration-300 hover:scale-125 hover:-translate-y-1`} data-tip="Portfolio">
                                                                                                              <BsGlobe size={25} />
                                                                                                    </span>
                                                                                                    <span onClick={() => handleClick('https://g.co/kgs/jRV7pv')} className={`${styles.google} cursor-pointer tooltip inline-flex justify-center items-center text-white relative flex-shrink-0 w-12 h-12 m-3 md:m-4 rounded-xl md:rounded-2xl transition-all duration-300 hover:scale-125 hover:-translate-y-1`} data-tip='Google'>
                                                                                                              <FcGoogle size={30} />
                                                                                                    </span>
                                                                                                    <span onClick={() => handleClick('https://facebook.com/toufiqhasankiron')} className={`${styles.facebook} cursor-pointer tooltip inline-flex justify-center items-center text-white relative flex-shrink-0 w-12 h-12 m-3 md:m-4 rounded-xl md:rounded-2xl transition-all duration-300 hover:scale-125 hover:-translate-y-1`} data-tip='Facebook'>
                                                                                                              <BsFacebook size={25} />
                                                                                                    </span>
                                                                                                    <span onClick={() => handleClick('https://m.me/toufiqhasankiron')} className={`${styles.messenger} cursor-pointer tooltip inline-flex justify-center items-center text-white relative flex-shrink-0 w-12 h-12 m-3 md:m-4 rounded-xl md:rounded-2xl transition-all duration-300 hover:scale-125 hover:-translate-y-1W`} data-tip="Messenger">
                                                                                                              <BsMessenger size={25} />
                                                                                                    </span>
                                                                                                    <span onClick={() => handleClick('https://github.com/kiron0')} className={`${styles.github} cursor-pointer tooltip inline-flex justify-center items-center text-white relative flex-shrink-0 w-12 h-12 m-3 md:m-4 rounded-xl md:rounded-2xl transition-all duration-300 hover:scale-125 hover:-translate-y-1`} data-tip="GitHub">
                                                                                                              <BsGithub size={25} />
                                                                                                    </span>
                                                                                                    <span onClick={() => handleClick('https://instagram.com/toufiq_hasan_kiron/')} className={`${styles.instagram} cursor-pointer tooltip inline-flex justify-center items-center text-white relative flex-shrink-0 w-12 h-12 m-3 md:m-4 rounded-xl md:rounded-2xl transition-all duration-300 hover:scale-125 hover:-translate-y-1`} data-tip='Instagram'>
                                                                                                              <BsInstagram size={25} />
                                                                                                    </span>
                                                                                                    <span onClick={() => handleClick('https://linkedin.com/in/toufiq-hasan-kiron/')} className={`${styles.linkedin} cursor-pointer tooltip inline-flex justify-center items-center text-white relative flex-shrink-0 w-12 h-12 m-3 md:m-4 rounded-xl md:rounded-2xl transition-all duration-300 hover:scale-125 hover:-translate-y-1`} data-tip='LinkedIn'>
                                                                                                              <BsLinkedin size={25} />
                                                                                                    </span>
                                                                                                    <span onClick={() => handleClick('https://youtube.com/@toufiqhasankiron')} className={`${styles.youtube} cursor-pointer tooltip inline-flex justify-center items-center text-white relative flex-shrink-0 w-12 h-12 m-3 md:m-4 rounded-xl md:rounded-2xl transition-all duration-300 hover:scale-125 hover:-translate-y-1`} data-tip='YouTube'>
                                                                                                              <BsYoutube size={25} />
                                                                                                    </span>
                                                                                                    <span onClick={() => handleClick('https://x.com/hashtagkiron')} className={`${styles.twitter} cursor-pointer tooltip inline-flex justify-center items-center text-white relative flex-shrink-0 w-12 h-12 m-3 md:m-4 rounded-xl md:rounded-2xl transition-all duration-300 hover:scale-125 hover:-translate-y-1`} data-tip='Twitter'>
                                                                                                              <FaXTwitter size={25} />
                                                                                                    </span>
                                                                                                    <span onClick={() => handleClick('https://tiktok.com/@toufiqhasankiron')} className={`${styles.tiktok} cursor-pointer tooltip inline-flex justify-center items-center text-white relative flex-shrink-0 w-12 h-12 m-3 md:m-4 rounded-xl md:rounded-2xl transition-all duration-300 hover:scale-125 hover:-translate-y-1`} data-tip='TikTok'>
                                                                                                              <BsTiktok size={25} />
                                                                                                    </span>
                                                                                                    <span onClick={() => handleClick('https://reddit.com/user/toufiqhasankiron/')} className={`${styles.reddit} cursor-pointer tooltip inline-flex justify-center items-center text-white relative flex-shrink-0 w-12 h-12 m-3 md:m-4 rounded-xl md:rounded-2xl transition-all duration-300 hover:scale-125 hover:-translate-y-1`} data-tip='Reddit'>
                                                                                                              <BsReddit size={25} />
                                                                                                    </span>
                                                                                                    <span onClick={() => handleClick("https://api.whatsapp.com/send?phone=+8801855535091&text=Hey, what's up?")} className={`${styles.whatsapp} cursor-pointer tooltip inline-flex justify-center items-center text-white relative flex-shrink-0 w-12 h-12 m-3 md:m-4 rounded-xl md:rounded-2xl transition-all duration-300 hover:scale-125 hover:-translate-y-1`} data-tip='WhatsApp'>
                                                                                                              <BsWhatsapp size={25} />
                                                                                                    </span>
                                                                                                    <span onClick={() => handleClick('https://t.me/toufiqhasankiron')} className={`${styles.telegram} cursor-pointer tooltip inline-flex justify-center items-center text-white relative flex-shrink-0 w-12 h-12 m-3 md:m-4 rounded-xl md:rounded-2xl transition-all duration-300 hover:scale-125 hover:-translate-y-1`} data-tip='Telegram'>
                                                                                                              <BsTelegram size={25} />
                                                                                                    </span>
                                                                                                    <span onClick={() => handleClick('https://open.spotify.com/artist/7jHy9ns5u5jyqpWITHuWaV')} className={`${styles.spotify} cursor-pointer tooltip inline-flex justify-center items-center text-white relative flex-shrink-0 w-12 h-12 m-3 md:m-4 rounded-xl md:rounded-2xl transition-all duration-300 hover:scale-125 hover:-translate-y-1`} data-tip='Spotify'>
                                                                                                              <BsSpotify size={25} />
                                                                                                    </span>
                                                                                                    <span onClick={() => handleClick('https://music.apple.com/us/artist/toufiq-hasan-kiron/1696431264')} className={`${styles.applemusic} cursor-pointer tooltip inline-flex justify-center items-center text-white relative flex-shrink-0 w-12 h-12 m-3 md:m-4 rounded-xl md:rounded-2xl transition-all duration-300 hover:scale-125 hover:-translate-y-1`} data-tip='Apple Music'>
                                                                                                              <SiApplemusic size={25} />
                                                                                                    </span>
                                                                                                    <span onClick={() => handleClick('https://www.deezer.com/en/artist/216852415')} className={`${styles.deezer} cursor-pointer tooltip inline-flex justify-center items-center text-white relative flex-shrink-0 w-12 h-12 m-3 md:m-4 rounded-xl md:rounded-2xl transition-all duration-300 hover:scale-125 hover:-translate-y-1`} data-tip='Apple Music'>
                                                                                                              <FaDeezer size={25} />
                                                                                                    </span>
                                                                                                    <span onClick={() => handleClick('https://stackoverflow.com/users/16756623/toufiq-hasan-kiron')} className={`${styles.stackoverflow} cursor-pointer tooltip inline-flex justify-center items-center text-white relative flex-shrink-0 w-12 h-12 m-3 md:m-4 rounded-xl md:rounded-2xl transition-all duration-300 hover:scale-125 hover:-translate-y-1`} data-tip='Stack Overflow'>
                                                                                                              <BsStackOverflow size={25} />
                                                                                                    </span>

                                                                                                    <span onClick={() => handleClick('https://imdb.com/name/nm13862500')} className={`${styles.imdb} cursor-pointer tooltip inline-flex justify-center items-center text-white relative flex-shrink-0 w-12 h-12 m-3 md:m-4 rounded-xl md:rounded-2xl transition-all duration-300 hover:scale-125 hover:-translate-y-1`} data-tip='IMDB'>
                                                                                                              <img src={IMDb} alt="" className='w-24 rounded-xl md:rounded-2xl' />
                                                                                                    </span>

                                                                                                    <span onClick={() => handleClick('https://pinterest.com/toufiqhasankiron')} className={`${styles.pinterest} cursor-pointer tooltip inline-flex justify-center items-center text-white relative flex-shrink-0 w-12 h-12 m-3 md:m-4 rounded-xl md:rounded-2xl transition-all duration-300 hover:scale-125 hover:-translate-y-1`} data-tip='Pinterest'>
                                                                                                              <BsPinterest size={25} />
                                                                                                    </span>

                                                                                                    <span onClick={() => handleClick('https://g.dev/kiron')} className={`${styles.googleDev} cursor-pointer tooltip inline-flex justify-center items-center text-white relative flex-shrink-0 w-12 h-12 m-3 md:m-4 rounded-xl md:rounded-2xl transition-all duration-300 hover:scale-125 hover:-translate-y-1`} data-tip='Google Dev'>
                                                                                                              <svg width="24" height="24" viewBox="0 0 256 125" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" preserveAspectRatio="xMidYMid">
                                                                                                                        <g>
                                                                                                                                  <path d="M184.31481,67.7044587 C197.78381,59.9684587 211.21681,52.1694587 224.73181,44.5134587 C237.55981,37.2454587 252.65281,43.9484587 255.56081,58.0924587 C257.24381,66.2744587 253.59081,75.1134587 246.20381,79.4184587 C221.98581,93.5314587 197.73281,107.588459 173.35881,121.430459 C165.81481,125.714459 158.04381,124.926459 151.16881,119.676459 C144.18481,114.346459 141.98381,106.994459 143.62181,98.4374587 C145.49181,91.9234587 149.77181,87.5684587 155.60881,84.3104587 C165.25981,78.9244587 174.75381,73.2544587 184.31481,67.7044587" fill="#FABC05"></path>
                                                                                                                                  <path d="M194.20341,62.0789587 C180.76841,54.2829587 167.29941,46.5479587 153.91141,38.6729587 C141.20241,31.1969587 139.46241,14.7749587 150.25641,5.18295871 C156.50041,-0.364041286 165.98141,-1.62104129 173.40341,2.62395871 C197.73541,16.5409587 222.03541,30.5169587 246.20941,44.7039587 C253.69141,49.0949587 256.89341,56.2199587 255.78641,64.7989587 C254.66141,73.5109587 249.39541,79.0929587 241.16641,81.9539587 C234.58941,83.5919587 228.67841,82.0619587 222.93841,78.6359587 C213.44741,72.9709587 203.79041,67.5829587 194.20341,62.0789587" fill="#109D58"></path>
                                                                                                                                  <path d="M71.7518102,56.5628587 C63.1308102,61.4608587 54.5048102,66.3498587 45.8928102,71.2628587 C40.8548102,74.1368587 35.8728102,77.1088587 30.8088102,79.9348587 C20.6058102,85.6298587 8.48381017,82.2918587 2.69881017,72.2608587 C-2.82218983,62.6888587 0.35081017,50.2788587 10.1768102,44.5428587 C34.2018102,30.5198587 58.2888102,16.5988587 82.4628102,2.83385871 C89.8768102,-1.38814129 97.5688102,-0.857141286 104.42481,4.18985871 C111.66081,9.51685871 114.02981,17.0128587 112.40481,25.8008587 C111.39681,27.9268587 110.79481,30.4198587 109.28581,32.0948587 C106.83081,34.8198587 104.04081,37.4288587 100.93481,39.3448587 C91.3228102,45.2718587 81.4958102,50.8498587 71.7518102,56.5628587" fill="#E94436"></path>
                                                                                                                                  <path d="M61.8670102,62.0569587 C70.4200102,67.0729587 78.9670102,72.0999587 87.5280102,77.1019587 C92.5350102,80.0279587 97.6000102,82.8569587 102.57901,85.8279587 C112.61301,91.8179587 115.78401,103.983959 109.98901,114.008959 C104.45901,123.576959 92.1260102,127.034959 82.2450102,121.391959 C58.0880102,107.596959 33.9890102,93.6989587 9.98101017,79.6459587 C2.61801017,75.3359587 -0.76798983,68.4089587 0.17501017,59.9479587 C1.17001017,51.0169587 6.47701017,45.2179587 14.9000102,42.2309587 C17.2450102,42.0419587 19.7050102,41.3159587 21.9110102,41.7859587 C25.4980102,42.5499587 29.1530102,43.6609587 32.3650102,45.3929587 C42.3040102,50.7529587 52.0480102,56.4749587 61.8670102,62.0569587" fill="#4385F3"></path>
                                                                                                                        </g>
                                                                                                              </svg>
                                                                                                    </span>
                                                                                          </div>
                                                                                </Fade>
                                                                      </div>
                                                            </div>
                                                  </div>
                                                  <Fade bottom duration={3000} distance="40px">
                                                            <div className={`bg-transparent md:hidden z-50 absolute top-4 right-2 md:right-4 rounded-xl px-3 py-1`}>
                                                                      <button
                                                                                onClick={handleThemeChange}>
                                                                                {
                                                                                          theme ? (
                                                                                                    <BiSun size={20} className={`${theme ? 'text-white' : 'text-black'}`} />
                                                                                          ) : (
                                                                                                    <BiMoon size={20} className={`${theme ? 'text-white' : 'text-black'}`} />
                                                                                          )
                                                                                }
                                                                      </button>
                                                            </div>
                                                  </Fade>
                                        </div>
                              </div>
                    </Fade>
          )
}
