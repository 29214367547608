import React, { useState, useEffect, createContext } from 'react';
import Profile from './components/Profile';
import Preloader from './shared/Preloader';
import TsParticles from './utils/tsParticles';


export const InitializeContext = createContext(null as any);

export default function App() {
  const [theme, setTheme] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);



  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 2500);
  }, []);

  useEffect(() => {
    document.addEventListener("contextmenu", (e) => {
      e.preventDefault();
    });
  }, []);

  useEffect(() => {
    setTheme(JSON.parse(window.localStorage.getItem("profileTheme") || false as any));
  }, []);

  const handleThemeChange = () => {
    setTheme(!theme);
    window.localStorage.setItem("profileTheme", !theme as any);
  };

  return (
    <InitializeContext.Provider value={{ handleThemeChange, theme }}>
      <div data-theme={theme ? 'dark' : 'light'} className={`bg-base-100 overflow-x-hidden`}>
        {
          loading ? (
            <Preloader />
          ) : (
            <>
              <Profile />
              <TsParticles />
            </>
          )}
      </div>
    </InitializeContext.Provider>
  );
}
